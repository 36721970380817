const ITEMS_PER_PAGE = 10
const AUTO_SCROLL_PATH = ['blog', 'search']

// social media
const FACEBOOK_URL = 'https://www.facebook.com/sharer/sharer.php?u='
const TWITTER_URL = 'https://twitter.com/intent/tweet?url='
const XING_URL = 'https://www.xing.com/spi/shares/new?url='

// SECmarket sites
const BLOG_URL = process.env.GATSBY_BLOG_URL
const INTERNAL_BLOG_URL = process.env.GATSBY_INTERNAL_BLOG_URL
const LANDING_PAGE_URL =
  process.env.GATSBY_LANDING_PAGE_URL || 'https://stg.secmarket.de/'
const JOB_PLATFORM_URL =
  process.env.GATSBY_JOB_PLATFORM_URL || 'https://jobs.secmarket.de/'
const BOOKING_PLATFORM_URL =
  process.env.GATSBY_BOOKING_PLATFORM_URL || 'https://booking.secmarket.de/'

const GOOGLE_TAG_MANAGER_KEY =
  process.env.GATSBY_GOOGLE_TAG_MANAGER_KEY || 'GTM-KMH3BRH'

const COOKIE_PRO_ID = process.env.GATSBY_COOKIE_PRO_ID

// contentful settings
const CONTENTFUL_SPACE_ID = process.env.GATSBY_CONTENTFUL_SPACE_ID
const CONTENTFUL_ACCESS_TOKEN = process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN
const CONTENTFUL_PREVIEW_TOKEN = process.env.GATSBY_CONTENTFUL_PREVIEW_TOKEN
const CONTENTFUL_PREVIEW_HOST =
  process.env.GATSBY_CONTENTFUL_PREVIEW_HOST || 'preview.contentful.com'
const CONTENTFUL_ENVIRONMENT =
  process.env.GATSBY_CONTENTFUL_ENVIRONMENT || 'master'

const ALL_TAGS = 'Alle'

const PREVIEW = 'preview'
const isPreview = paths => paths.some(path => path === PREVIEW)

module.exports = {
  CONTENTFUL_PREVIEW_TOKEN,
  CONTENTFUL_PREVIEW_HOST,
  CONTENTFUL_ACCESS_TOKEN,
  GOOGLE_TAG_MANAGER_KEY,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_SPACE_ID,
  AUTO_SCROLL_PATH,
  ITEMS_PER_PAGE,
  FACEBOOK_URL,
  TWITTER_URL,
  isPreview,
  XING_URL,
  INTERNAL_BLOG_URL,
  BLOG_URL,
  LANDING_PAGE_URL,
  JOB_PLATFORM_URL,
  BOOKING_PLATFORM_URL,
  ALL_TAGS,
  COOKIE_PRO_ID,
}
