const { AUTO_SCROLL_PATH } = require('./src/consts')
require('./src/global.css')

export { wrapRootElement } from './browserApis'

export const shouldUpdateScroll = ({
  routerProps: { location: currLocation },
}) => {
  if (
    AUTO_SCROLL_PATH.includes(
      currLocation.pathname.split('/').filter(path => path)[0],
    )
  ) {
    return true
  }
  return false
}
